import SmoothScroll from "smooth-scroll";

const pageNavigation = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  offset: () => {
    const header = document.querySelector("header");
    const headerHeight = header.offsetHeight;
    return headerHeight;
  },
});
