const HEADER_ACTIVE_CLASS = "header-active";
const HEADER_ACTIVE_SCROLL_DISTANCE = 0;
const ENABLE_HEADER_SCROLL = true;
const ENABLE_NO_SCROLL_FOR_NAV = true;

let headerActive = false;

function handleScroll() {
  const scroll = (window.pageYOffset || document.scrollTop || 0) - (document.clientTop || 0);

  const headerShouldBeActive = scroll > HEADER_ACTIVE_SCROLL_DISTANCE;
  if (headerShouldBeActive === headerActive) {
    return;
  }

  headerActive = headerShouldBeActive;

  if (headerActive) {
    document.body.classList.add(HEADER_ACTIVE_CLASS);
    return;
  }

  document.body.classList.remove(HEADER_ACTIVE_CLASS);
}

if (ENABLE_HEADER_SCROLL) {
  window.addEventListener("scroll", handleScroll);
  handleScroll();
}
