const SELECTOR_NAME = "#name";
const SELECTOR_EMAIL = "#email";
const SELECTOR_PHONE = "#phone";
const SELECTOR_MESSAGE = "#message";
const SELECTOR_MESSAGE_CONTAINER = ".form__message";
const SELECTOR_SUBMIT = ".form__submit";

const messageSuccess = "Thanks for your submission";
const messageError = "Please enter a valid email";

const API_FORM_URL = "https://jy016llodh.execute-api.ap-southeast-2.amazonaws.com/prod/send-email";
const API_MAIL_DOMAIN = "focus-australia";
const TO = "enquiries@focusau.com.au";
const FROM = "noreply@mitchheddles.com.au";
const SUBJECT = "Focus Website Contact";

const form = document.getElementById("contact-form");

const nameInput = form.querySelector(SELECTOR_NAME);
const emailInput = form.querySelector(SELECTOR_EMAIL);
const phoneInput = form.querySelector(SELECTOR_PHONE);
const messageInput = form.querySelector(SELECTOR_MESSAGE);
const formMessageContainer = form.querySelector(SELECTOR_MESSAGE_CONTAINER);
const formSubmit = form.querySelector(SELECTOR_SUBMIT);

form.addEventListener("submit", handleSubmit);

function handleSubmit(event) {
  event.preventDefault();

  const isLoading = form.classList.contains("loading");

  if (isLoading) {
    return;
  }

  formMessageContainer.innerText = "";
  nameInput.classList.remove("input--error");
  emailInput.classList.remove("input--error");
  phoneInput.classList.remove("input--error");
  messageInput.classList.remove("input--error");
  form.classList.remove("error");

  const data = {
    name: nameInput.value,
    email: emailInput.value,
    message: messageInput.value,
    phone: phoneInput.value,
  };

  const errorMessage = validateForm(data);

  if (errorMessage) {
    form.classList.add("error");
    formMessageContainer.innerText = errorMessage;

    return;
  }

  form.classList.remove("error");
  form.classList.add("loading");

  const body = {
    domain: API_MAIL_DOMAIN,
    to: TO,
    from: FROM,
    subject: SUBJECT,
    html: `Hi,<br /><br />There was a new website submission.<br /><br />Name: ${data.name}<br />Email: ${data.email}<br />Phone: ${data.phone}<br />Message: ${data.message}<br />`,
  };

  fetch(API_FORM_URL, {
    method: "POST",
    body: JSON.stringify(body),
  })
    .then((res) => {
      form.classList.remove("loading");
      form.classList.add("success");
      formMessageContainer.innerText = messageSuccess;

      nameInput.value = "";
      emailInput.value = "";
      messageInput.value = "";
      phoneInput.value = "";
    })
    .catch((err) => {
      form.classList.remove("loading");
      form.classList.add("error");
      formMessageContainer.innerText = errorMessage;
    });
}

function validateForm({ email, name, message, phone }) {
  if (!name) {
    nameInput.classList.add("input--error");
    return "Please enter your name";
  }

  if (email.length < 5 || !email.includes("@")) {
    emailInput.classList.add("input--error");
    return "Please enter a valid email";
  }

  if (phone.length < 5) {
    phoneInput.classList.add("input--error");
    return "Please enter a valid phone number";
  }

  if (!message) {
    messageInput.classList.add("input--error");
    return "Please enter a message";
  }
}
